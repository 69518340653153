<template>
  <div class="todo">
    <div class="page-header flex">
      <span style="font-size:16px;">任务清单</span>
      <!--<a-radio-group v-model="tabPosition" style="margin:8px">-->
      <!--<a-radio-button value="top">任务清单</a-radio-button>-->
      <!--</a-radio-group>-->
      <div>
        <div class="screen-btn ant-menu-item-selected" @click="_addTodo">
          <a-icon type="plus-circle" />
          <span>创建清单</span>
        </div>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="screen-list flex">
        <div class="screen-list-item flex">
          <span class="screen-item-label">任务名称</span>
          <a-input
            placeholder="请输入关键字"
            style="flex: 1;height: 36px;"
            v-model="searchInput"
          />
        </div>

        <div class="screen-list-item flex">
          <div class="flex" style="flex:1">
            <span class="screen-item-label">所属分类</span>
            <a-tree-select
              style="flex:1"
              :treeData="classifyList"
              :value="classify"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allowClear
              treeDefaultExpandAll
              @change="_classify"
            >
            </a-tree-select>
          </div>
        </div>
        <div class="screen-list-item flex">
          <span class="screen-item-label">创建时间</span>
          <a-range-picker
            style="flex: 1"
            format="YYYY-MM-DD"
            :value="startEndDate"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="_createdDate"
          />
        </div>
      </div>
      <div class="todo-content">
        <div style="text-align: right;margin: 15px  0;padding: 5px 10px;">
          <a-button
            style="width: 110px;height: 34px;margin-right: 20px"
            @click="_resetBtn"
            >重置条件</a-button
          >
          <a-button
            type="primary"
            style="width: 110px;height: 34px;"
            @click="_searchBtn"
            >开始查询</a-button
          >
        </div>
        <a-table
          :columns="columns"
          :dataSource="infoData"
          :pagination="false"
          bordered
          v-if="infoData"
        >
          <div slot="action" slot-scope="text, record">
            <a-icon
              type="form"
              class="icon-btn ant-menu-item-selected"
              @click="_todoDetail(record)"
            />
            <a-popconfirm
              placement="top"
              cancelText="取消"
              okText="确定"
              @confirm="_removeItem(record)"
            >
              <template slot="title">
                <p>确定删除么？</p>
              </template>
              <a-icon type="delete" class="icon-btn ant-menu-item-selected" />
            </a-popconfirm>
          </div>
        </a-table>

        <div style="margin: 20px 0;text-align: right">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="pageTotal"
            @change="_PageChange"
          />
        </div>
      </div>
      <a-drawer
        :title="drawerAlertTitle"
        placement="right"
        :closable="false"
        @close="onClose"
        :visible="visible"
        width="450"
      >
        <a-form layout="vertical" hideRequiredMark>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="任务名称">
                <a-input
                  style="height: 36px;margin-top:5px;"
                  v-model="todoTitle"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        { required: true, message: 'Please enter user name' }
                      ]
                    }
                  ]"
                  placeholder="请输入..."
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="所属分类">
                <a-tree-select
                  style="flex:1"
                  :treeData="classifyList"
                  :value="selectdefaultValue"
                  :defaultValue="selectdefaultValue"
                  :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allowClear
                  treeDefaultExpandAll
                  @change="_selectdefaultValue"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="备注">
                <a-input
                  style="margin-top:5px;resize: none;height: 120px;"
                  v-model="todoContent"
                  type="textarea"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        { required: true, message: 'Please enter user name' }
                      ]
                    }
                  ]"
                  placeholder="请输入..."
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <a-col :span="24" style="margin-bottom: 15px;">添加清单项</a-col>
          </a-row>

          <div
            v-for="(item, index) in todoChildren"
            style="margin-bottom: 15px;"
          >
            <a-row
              :gutter="16"
              class="flex"
              style="align-items: center;margin-bottom:20px;"
            >
              <a-col :span="20">
                <a-form-item style="margin-bottom: 0;padding-bottom: 0">
                  <a-input
                    style="height: 36px;"
                    v-decorator="[
                      'name',
                      {
                        rules: [
                          { required: true, message: 'Please enter user name' }
                        ]
                      }
                    ]"
                    placeholder="请输入清单名称..."
                    v-model="item.Title"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-dropdown placement="bottomRight">
                  <img
                    src="https://smart-resource.sikegroup.com/saas-more.png"
                    style="width: 25px;height:25px;cursor: pointer"
                    alt=""
                  />
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <div
                        style="padding:4px 15px"
                        @click="_uploadCancel(index)"
                      >
                        上传
                      </div>
                    </a-menu-item>
                    <a-menu-item>
                      <div
                        style="padding:4px 15px"
                        @click="_removeNewTodo(index)"
                      >
                        删除
                      </div>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-col>
            </a-row>
          </div>

          <a-row :gutter="16" style="margin-bottom: 40px;">
            <a-col :span="24">
              <a-button
                type="dashed"
                style="height: 36px"
                block
                @click="_newTodo"
                >+ 添加清单项</a-button
              >
            </a-col>
          </a-row>
        </a-form>
        <div class="drawer-bottom">
          <a-button
            style="width: 120px;text-align: center;margin-right:20px;"
            @click="onClose"
            >取消</a-button
          >
          <a-button
            type="primary"
            style="width: 120px;text-align: center"
            @click="_saveTodo"
            >{{ detailData ? '修改' : '提交' }}</a-button
          >
        </div>

        <a-drawer
          width="450"
          :closable="false"
          @close="onChildrenDrawerClose"
          :visible="childrenDrawer"
        >
          <div class="flex" style="margin-bottom: 15px;">
            <span>文件材料</span>
            <a-button type="primary" style="position: relative"
              >上传文件<input
                type="file"
                @change="_uploadFile"
                class="uploadFile"
                style="position: absolute;top:0;left:0;width:100%;height:100%;opacity: 0"
            /></a-button>
          </div>

          <div
            class="flex"
            style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap;align-items: end"
            v-if="alertFileList || alertFileList.length != 0"
          >
            <a-dropdown
              :trigger="['contextmenu']"
              v-for="(item, index) in alertFileList"
            >
              <div class="upload-file-item">
                <a-icon type="file-pdf" />
                <div>{{ item.FileName }}</div>
              </div>
              <a-menu slot="overlay">
                <a-menu-item key="1" style="text-align: center;">
                  <div
                    style="position:relative;overflow: hidden;"
                    @click="
                      item.FileType.indexOf('image/') == 0 &&
                      item.FileName.indexOf('.svg') < 1
                        ? ''
                        : lookFn_cx(item)
                    "
                  >
                    <i class="iconfont icon-yulan"></i> 预览
                    <viewer
                      v-if="
                        item.FileType.indexOf('image/') == 0 &&
                          item.FileName.indexOf('.svg') < 1
                      "
                      class="imgLook"
                    >
                      <img
                        :src="item.FileUrl"
                        style="width:100%;height:100%;"
                      />
                    </viewer>
                  </div>
                </a-menu-item>
                <a-menu-item
                  key="2"
                  style="text-align: center"
                  @click="_delFile(item, index)"
                  ><i class="iconfont icon-shanchu-"></i> 删除</a-menu-item
                >
                <a-menu-item key="3" style="text-align: center"
                  ><a :href="item.FileUrl + '?attname=' + item.FileName"
                    ><i class="iconfont icon-xiazai-"></i> 下载</a
                  ></a-menu-item
                >
              </a-menu>
            </a-dropdown>
          </div>

          <div class="flex" style="margin-bottom: 15px;">
            <span>清单说明</span>
          </div>
          <div>
            <a-input
              placeholder="请输入..."
              type="textarea"
              style="height:120px;resize: none"
              v-model="todoTextArea"
            />
          </div>
          <div class="drawer-bottom">
            <a-button
              style="width: 120px;text-align: center;margin-right:20px;"
              @click="onChildrenDrawerClose"
              >取消</a-button
            >
            <a-button
              type="primary"
              style="width: 120px;text-align: center"
              @click="_addTodoTextModel(true)"
              >确定</a-button
            >
          </div>
        </a-drawer>
      </a-drawer>
    </div>
    <div
      v-if="previewBool && lookInfo"
      style="position:fixed;top: 0px;width:100%;bottom:0px;left:0px;background:rgba(0,0,0,.5);z-index: 2000;"
      @click.stop="previewBool = ''"
    >
      <div class="lookBox">
        <div
          style="background: #fff;position: fixed;width: 90%;height:90%;left: 50%;top:50%;transform: translate(-50%,-50%)"
        >
          <!--svg 图标-->
          <object
            v-if="lookInfo.FileName.indexOf('.svg') > 0"
            :data="lookInfo.FileUrl"
            width="600"
            height="600"
            style="vertical-align: middle;"
            type="image/svg+xml"
            codebase="http://www.adobe.com/svg/viewer/imstall/"
          />
          <!--pdf文件-->
          <iframe
            v-if="
              lookInfo.FileName.indexOf('.pdf') > 0 ||
                lookInfo.FileName.indexOf('.txt') > 0
            "
            :src="lookInfo.FileUrl"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
          <video
            v-else-if="lookInfo.FileType.indexOf('video/') == 0"
            controls
            :src="lookInfo.FileUrl"
            style="max-height:680px;max-width:800px;vertical-align: middle;"
          >
            您的浏览器不支持视频播放。
          </video>
          <audio
            v-else-if="lookInfo.FileType.indexOf('audio/') == 0"
            :src="lookInfo.FileUrl"
            controls="controls"
          >
            您的浏览器不支持 audio 标签。
          </audio>
          <iframe
            v-else
            :src="
              'https://view.officeapps.live.com/op/view.aspx?src=' +
                lookInfo.FileUrl
            "
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/assets/axios/axios'
import myAxios from 'axios'
import E from 'wangeditor'

import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

const http = myAxios.create({
  baseURL: 'https://smart.sikegroup.com',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  transformRequest: [
    function (data) {
      let newData = ''
      for (let k in data) {
        if (data.hasOwnProperty(k) === true) {
          newData +=
            encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
        }
      }
      return newData
    }
  ]
})
const columns = [
  {
    title: '序号',
    dataIndex: 'sNumber',
    key: 'sNumber',
    align: 'center',
  },
  {
    title: '任务名称',
    dataIndex: 'Title',
    key: 'Title',
    align: 'center',
  },
  {
    title: '所属分类',
    dataIndex: 'CategoryName',
    key: 'CategoryName',
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'CreateUserName',
    key: 'CreateUserName',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'CreateTime',
    key: 'CreateTime',
    align: 'center',
  },
  { title: '备注', dataIndex: 'Content', key: 'Content' },
  {
    title: '操作栏',
    dataIndex: '',
    key: 'x',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 100
  }
]
const userinfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'todo',
  data () {
    return {
      tabPosition: 'top',
      columns: columns,
      searchInput: '',
      creater: '',
      classify: '',
      classifyList: [],
      startDate: '',
      endDate: '',
      infoData: [],
      pageTotal: 0,
      page: 1,
      visible: false,
      childrenDrawer: false,
      addTodoTextModel: false,
      addTodoTextIndex: '',
      todoTextArea: '',
      todoChildren: [],
      todoContent: '',
      todoTitle: '',
      AddClassify: '',
      detailData: '',
      selectdefaultValue: '',
      detailId: '',
      startEndDate: [],
      alertFileList: [],
      drawerAlertTitle: '添加任务清单',

      previewBool: false, //预览
      lookInfo: ''
    }
  },
  created () {
    this._info()
    //获取分类
    this.$axios.get(1206, { pid: '0c8eec436df14bc8daf0fae6e932e040' }, res => {
      if (res.data.code == 1) {
        this.classifyList = res.data.data
      }
    })
  },
  methods: {
    _dbyulan () {
      console.log(1111)
    },
    //预览
    lookFn_cx (obj) {
      this.previewBool = true
      this.lookInfo = obj
    },
    _selectdefaultValue (value) {
      this.selectdefaultValue = value
      this.AddClassify = value
    },
    onChildrenDrawerClose () {
      this.childrenDrawer = false
      this.addTodoTextIndex = ''
      this.todoTextArea = ''
      this.alertFileList = []
    },
    _resetBtn () {
      this.searchInput = ''
      this.classify = ''
      this.startDate = ''
      this.endDate = ''
      this.startEndDate = []
    },
    _delFile (data, i) {
      this.$axios.post(
        5836123,
        { UserId: userinfo.uid, todoId: this.detailId, Id: data.Id },
        res => {
          if (res.data.code == 1) {
            this.alertFileList.splice(i, 1)
            this.$message.success('删除成功')
          }
        }
      )
    },
    _removeFile (i, fileIndex) {
      this.todoChildren[i].fileList.splice(fileIndex, 1)
    },
    _removeContent (i) {
      this.todoChildren[i].Content = ''
    },
    _uploadFile (e) {
      this.__uploadFile__(
        document.getElementsByClassName('uploadFile')[0].files
      ).then(res => {
        this.alertFileList = this.alertFileList.concat(res)
      })
    },
    _todoDetail (value) {
      let self = this
      let data = {
        CreateUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        Id: value.Id
      }
      this.drawerAlertTitle = '修改任务清单'
      axios.get(1209, data, res => {
        if (res.data.code == 1) {
          this.detailData = res.data.data
          this.visible = true
          this.todoTitle = this.detailData.info.Title
          this.todoContent = this.detailData.info.Content
          this.todoChildren = this.detailData.Children
          this.AddClassify = this.detailData.info.CategoryId
          this.selectdefaultValue = this.detailData.info.CategoryId
          this.detailId = value.Id
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    _removeItem (value) {
      let data = {
        CreateUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        IsDelChild: 1,
        Id: value.Id
      }
      let self = this
      axios.post(1208, data, res => {
        if (res.data.code == 1) {
          this.infoData.splice(value.key - 1, 1)
          self.$message.info(res.data.msg)
        } else {
          self.$message.error(res.data.msg)
        }
      })
    },
    _saveTodo () {
      if (this.todoTitle == '') {
        this.$message.error('任务名称必填')
        return false
      } else if (this.CategoryId == '') {
        this.$message.error('所属分类必填')
        return false
      }
      let data = {
        Title: this.todoTitle,
        CategoryId: this.AddClassify,
        CreateUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        CreateUserName: JSON.parse(window.sessionStorage.getItem('userInfo'))
          .name,
        Content: this.todoContent,
        Children: JSON.stringify(this.todoChildren)
      }
      let setData = {
        Id: this.detailId,
        Title: this.todoTitle,
        CategoryId: this.AddClassify,
        CreateUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        CreateUserName: JSON.parse(window.sessionStorage.getItem('userInfo'))
          .name,
        Content: this.todoContent,
        Children: JSON.stringify(this.todoChildren)
      }
      let url = this.detailData ? 1210 : 1207
      let subData = this.detailData ? setData : data
      axios.post(url, subData, res => {
        if (res.data.code == 1) {
          this.$message.info(res.data.msg)
          this.page = 1
          this._info()
          this.visible = false
          this.todoTitle = ''
          this.AddClassify = ''
          this.todoContent = ''
          this.todoChildren = []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    _setTodoText (i) {
      this.todoTextArea = this.todoChildren[i].Content
      this.childrenDrawer = true
      this.addTodoTextIndex = i
    },
    _addTodoTextModel (bol) {
      if (bol) {
        this.todoChildren[this.addTodoTextIndex].Content = this.todoTextArea
        this.todoChildren[this.addTodoTextIndex].fileList = this.alertFileList
        this.alertFileList = []
        this.childrenDrawer = false
      } else {
        this.todoTextArea = ''
        this.childrenDrawer = false
      }
    },
    _removeNewTodo (i) {
      this.todoChildren.splice(i, 1)
    },
    _newTodo () {
      this.todoChildren.push({ Title: '', Content: '', fileList: [] })
    },
    _uploadConfirm (i) {
      this.addTodoTextIndex = i
    },
    _uploadCancel (i) {
      this.todoTextArea = this.todoChildren[i].Content
      this.alertFileList = this.todoChildren[i].fileList
      this.childrenDrawer = true
      this.addTodoTextIndex = i
    },
    _addTodo () {
      this.visible = true
      this.drawerAlertTitle = '添加任务清单'
    },
    _searchBtn () {
      this.page = 1
      this._info()
    },
    onClose () {
      this.visible = false
      this.detailData = ''
      this.todoTitle = ''
      this.todoContent = ''
      this.todoChildren = []
      this.AddClassify = ''
      this.selectdefaultValue = ''
      this.detailId = ''
    },
    _info () {
      this.$message.loading('加载中...')
      let data = {
        Title: this.searchInput,
        CategoryId: this.classify,
        startDate: this.startDate,
        endDate: this.endDate,
        Page: this.page
      }
      axios.get(1205, data, res => {
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.pageTotal = res.data.count
          this.page += this.page
        } else {
          this.pageTotal = 0
          this.infoData = []
        }
        this.$message.destroy()
      })
    },
    _PageChange (pageNumber) {
      this.page = pageNumber
      this._info()
    },
    _creater (value) {
      this.creater = value
    },
    _classify (value) {
      this.classify = value
    },
    _AddClassify (value) {
      this.AddClassify = value
    },
    _createdDate (value) {
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    }
  }
}
</script>

<style scoped lang="less">
.todo {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;
  .page-header {
    height: 55px;
    line-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e2e3;
    ul {
      li {
        width: 100px;
        padding: 8px 0;
        text-align: center;
        color: #666;
        display: inline-block;
        border: 1px solid #e1e2e3;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: none;
        border-right: none;
        cursor: pointer;
        &:last-child {
          border-right: 1px solid #e1e2e3;
        }
      }
      li.active {
        color: #fff !important;
        border: none;
      }
    }
    .screen-btn {
      width: 100px;
      display: inline-block;
      font-size: 12px;
      margin-left: 20px;
      border-width: 1px;
      border-style: solid;
      border-radius: 20px;
      padding: 9px 0;
      line-height: 1;
      text-align: center;
      background: #fff !important;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
  .screen-list {
    margin-top: 10px;
    .screen-list-item {
      margin-right: 20px;
      flex: 1;
    }
    .screen-item-label {
      margin-right: 10px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .icon-btn {
    cursor: pointer;
    margin-right: 15px;
    font-size: 18px;
  }
}
.drawer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
}
.operation-btn {
  font-size: 30px;
  color: #9a9a9a;
  cursor: pointer;
}
.todo-text {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  margin-left: 50px;
  background: #f8f9fa;
  border-radius: 2px;
  color: #999;
  padding-right: 40px;
  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
}
.ant-select-selection {
  height: 36px !important;
}
.upload-file-item {
  width: 100px;
  padding: 15px 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
  border-radius: 2px;
  i {
    font-size: 28px;
    margin-bottom: 10px;
  }
}
.lookBox {
  display: inline-table;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100vh;
  line-height: 100vh;
}
.imgLook {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  z-index: 220;
}
</style>
